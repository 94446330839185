import React from 'react'
import styled from 'styled-components'

export default () => {
  return (
    <Wrap>
      <svg
        width="21"
        height="156"
        viewBox="0 0 21 156"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.2907 145.2C16.4592 145.2 18.5094 146.469 18.5094 149.385C18.5094 151.626 17.2047 153.327 14.5155 153.462V155.703C18.2697 155.595 20.3199 152.868 20.3199 149.385C20.3199 145.308 17.5508 142.878 13.264 142.878H0.989435V145.2H13.2907Z"
          fill="black"
        />
        <path
          d="M20.0004 136.352V133.409H17.0982V136.352H20.0004Z"
          fill="black"
        />
        <path
          d="M20.0004 125.322V123H11.2937V112.322H20.0004V110H0.989435V112.322H9.37663V123H0.989435V125.322H20.0004Z"
          fill="black"
        />
        <path
          d="M20.0004 102.058V99.1151H17.0982V102.058H20.0004Z"
          fill="black"
        />
        <path
          d="M0.989435 84.5835V91.4146H20.0004V84.5835C20.0004 78.9945 16.2195 75.1876 10.4949 75.1876C4.79696 75.1876 0.989435 78.9945 0.989435 84.5835ZM2.90651 84.5835C2.90651 80.1015 5.91525 77.5905 10.4949 77.5905C15.1012 77.5905 18.0833 80.1015 18.0833 84.5835V89.1195H2.90651V84.5835Z"
          fill="black"
        />
        <path
          d="M20.0004 68.7964V66.4744H0.989435V68.7964H20.0004Z"
          fill="black"
        />
        <path
          d="M20.0004 45.6033V42.9033L9.8559 50.7063L0.989435 43.9023V46.4133L8.28496 51.9483L0.989435 57.4833V60.2103L10.0157 53.2983L20.0004 60.9393V58.4283L11.6132 52.0563L20.0004 45.6033Z"
          fill="black"
        />
        <path
          d="M10.4949 21.8313C4.79696 21.8313 0.669922 25.2603 0.669922 30.6063C0.669922 35.9523 4.79696 39.3813 10.4949 39.3813C16.2195 39.3813 20.3199 35.9523 20.3199 30.6063C20.3199 25.2603 16.2195 21.8313 10.4949 21.8313ZM10.4949 24.2343C15.1012 24.2343 18.3762 26.3943 18.3762 30.6063C18.3762 34.8453 15.1012 37.0053 10.4949 37.0053C5.91525 37.0053 2.61362 34.8453 2.61362 30.6063C2.61362 26.3943 5.91525 24.2343 10.4949 24.2343Z"
          fill="black"
        />
        <path
          d="M0.989023 12.406V15.268H20V13H3.78475V12.946L20.0004 2.16003V0H0.989435V2.24103H16.1663V2.29504L0.989023 12.406Z"
          fill="black"
        />
      </svg>
    </Wrap>
  )
}

const Wrap = styled.div`
  svg path {
    fill: ${props => props.theme.pageTheme.fg};
  }
`
