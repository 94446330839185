import React from 'react'
import styled from 'styled-components'

export default () => {
  return (
    <Wrap>
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 80V0H80V80H0ZM24 2H56V39H24V2ZM24 41V78H56V41H24ZM58 78V2H78V78H58ZM22 2H2V78H22V2Z"
          fill="#454545"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.4689 23.2056C41.4689 25.7466 40.4653 27.389 38.1609 27.389C36.3904 27.389 35.0451 26.3445 34.9383 24.1878H33.166C33.2514 27.1969 35.4081 28.8411 38.1609 28.8411C41.3835 28.8411 43.3035 26.6203 43.3035 23.1842V13.3457H41.4689V23.2056Z"
          fill="#454545"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.9809 52.7856H38.5665C42.1093 52.7856 44.0934 55.195 44.0934 58.8677C44.0934 62.5583 42.1093 64.9499 38.5665 64.9499H34.9809V52.7856ZM45.9922 58.8676C45.9922 54.3016 42.985 51.248 38.5666 51.248H33.166V66.4854H38.5666C42.985 66.4854 45.9922 63.455 45.9922 58.8676V58.8676Z"
          fill="#454545"
        />
      </svg>
    </Wrap>
  )
}

const Wrap = styled.div`
  svg path {
    fill: ${props => props.theme.pageTheme.navFg};
  }
`
