const addImageSharpData = (grid, dataImages) => {
  return grid.map(gridItem => {
    const data = dataImages.edges.filter(edge => {
      if (
        (gridItem.type === 'image' || !gridItem.type) &&
        gridItem.src === edge.node.relativePath
      ) {
        return true
      }
      return false
    })
    if (data.length > 0) {
      gridItem.childImageSharp = data[0].node.childImageSharp
    }
    return gridItem
  })
}

const makeGridWithData = (grid, dataImages) => {
  //const images = _.map(grid, 'image')

  return grid.map(gridItem => {
    // console.log('+++', gridItem)
    const data = dataImages.edges.filter(edge => {
      if (gridItem.image === edge.node.relativePath) {
        return true
      }
      return false
    })
    if (data.length > 0) {
      gridItem.childImageSharp = data[0].node.childImageSharp
    }
    return gridItem
  })
}

export { addImageSharpData, makeGridWithData }
