import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

export default ({ children }) => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true)
    }, 100)
  }, [])

  return <Wrap isReady={isReady}>{children}</Wrap>
}

const Wrap = styled.div`
  transition: opacity 0.2s ease-out, top 0.2s ease-out;
  position: relative;
  top: ${props => (props.isReady ? `0px` : `10px`)};
  opacity: ${props => (props.isReady ? `1` : `0`)};
`
