import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Flex } from 'reflexbox/styled-components'

import { PageRow } from '../global'

import NavBody from './body'
import NavFoot from './foot'
import NavHead from './head'

export default ({ isFoot, onToggle, isToggled, location }) => {
  let prevPath
  if (location) {
    prevPath = usePrevious(location.pathname)
  }

  useEffect(() => {
    if (location) {
      if (prevPath !== location.pathname) {
        if (typeof onToggle === 'function' && isToggled) {
          onToggle()
        }
      }
    }
  }, [location, prevPath, onToggle, isToggled])

  return (
    <Wrap isFoot={isFoot} isToggled={isToggled}>
      <PageRow fullHeight={true}>
        <LinksOuter justifyContent="space-between" flexDirection="column">
          <NavHead isFoot={isFoot} onToggle={onToggle} isToggled={isToggled} />
          <NavBody />
          <NavFoot />
        </LinksOuter>
      </PageRow>
    </Wrap>
  )
}

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const Wrap = styled.div`
  background-color: ${props => props.theme.pageTheme.navBg};
  height: 100vh;
  width: 100%;
  padding-top: 3rem;
  position: ${props => (props.isFoot ? `relative` : `fixed`)};
  left: 0;
  top: 0;
  z-index: ${props => (props.isFoot ? `1` : `100`)};
  display: ${props =>
    props.isFoot ? `block` : props.isToggled ? `block` : `none`};
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding-top: 1.8rem;
  }
`

const LinksOuter = styled(Flex)`
  height: 100%;
`
