import React from 'react'
import styled from 'styled-components'

export default ({ children }) => {
  return <Title>{children}</Title>
}

const Title = styled.div`
  ${props => props.theme.titleSizing()};
  margin-bottom: 0.4em;
  color: ${props => props.theme.pageTheme.fg};
  span {
    color: ${props => props.theme.pageTheme.fgAlt};
  }
`
