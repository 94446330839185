import React from 'react'

export default ({ color }) => {
  const fill = color ? color : `#BBAFA9`
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.1162 21.3662L20.6572 0.931641L0.222656 21.3662L2.98145 24.1494L18.6553 8.47559V41.3125H22.5615V8.35352L38.333 24.1494L41.1162 21.3662Z"
        fill={fill}
      />
    </svg>
  )
}
