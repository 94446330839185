import React from 'react'
import styled from 'styled-components'

export default () => {
  return (
    <Wrap>
      <svg
        width="20"
        height="148"
        viewBox="0 0 20 148"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.31919 7.52752C0.31919 11.7301 2.15452 14.2039 5.63897 14.2039C7.87328 14.2039 9.60221 12.7409 10.1874 10.4002C10.6662 12.0227 12.4749 13.3527 14.2304 13.3527C17.5553 13.3527 19.3108 10.9056 19.3108 6.67634V8.3015e-07L0.319191 0L0.31919 7.52752ZM10.9322 2.26092L17.4489 2.26092V6.83595C17.4489 9.65543 16.3051 11.0386 14.2038 11.0386C12.1291 11.0386 10.9322 9.62883 10.9322 6.80934V2.26092ZM9.15003 2.26092V7.3679C9.15003 10.2672 7.82009 11.9163 5.63897 11.9163C3.45786 11.9163 2.18111 10.3204 2.18111 7.42112L2.18111 2.26092L9.15003 2.26092Z"
          fill="black"
        />
        <path
          d="M8.05947 35.4935H19.3108L19.3108 33.206H7.92648C3.88344 33.206 1.94173 31.1579 1.94173 27.8064C1.94173 24.4815 3.88344 22.4068 7.92648 22.4068H19.3108V20.1193H8.11267C2.87269 20.1193 5.14751e-06 22.9654 4.9359e-06 27.8064C4.72197e-06 32.7006 2.87268 35.4935 8.05947 35.4935Z"
          fill="black"
        />
        <path
          d="M0.319189 42.668L0.319189 44.9556H19.3108V42.668H0.319189Z"
          fill="black"
        />
        <path
          d="M0.319188 52.43L0.319188 64.9315H2.23431L2.23431 54.7175H19.3108V52.43H0.319188Z"
          fill="black"
        />
        <path
          d="M19.3108 78.1687L19.3108 71.4392H0.319188L0.319187 78.1687C0.319187 83.6747 4.09623 87.4251 9.815 87.4251C15.5072 87.4251 19.3108 83.6747 19.3108 78.1687ZM17.3957 78.1687C17.3957 82.5841 14.39 85.0578 9.815 85.0578C5.21339 85.0578 2.23431 82.5841 2.23431 78.1687V73.7001H17.3957L17.3957 78.1687Z"
          fill="black"
        />
        <path
          d="M0.319186 106.569H2.23431L2.23431 95.9823H8.83084V105.372H10.7726V95.9823H17.3957V106.542H19.3108V93.7213H0.319187L0.319186 106.569Z"
          fill="black"
        />
        <path
          d="M0.319186 115.537H8.00627V120.591L0.319185 125.512L0.319185 128.092L8.35206 122.878C8.85744 125.113 10.5864 127.267 13.6718 127.267C17.3159 127.267 19.3108 124.847 19.3108 120.591L19.3108 113.276H0.319186L0.319186 115.537ZM9.9214 115.537H17.3957L17.3957 120.458C17.3957 123.33 16.0657 124.953 13.6718 124.953C11.1981 124.953 9.9214 123.33 9.9214 120.458V115.537Z"
          fill="black"
        />
        <path
          d="M5.93155 132.88C1.78212 133.146 1.80215e-07 136.604 0 140.727C-1.75564e-07 144.743 2.2875 147.616 5.53257 147.616C8.53825 147.616 9.89479 145.754 10.48 142.828L11.2513 139.104C11.7035 136.79 12.3685 135.726 14.257 135.726C16.2519 135.726 17.7947 137.375 17.7947 140.141C17.7947 142.855 16.5711 144.716 14.4432 145.036V147.243C17.8745 146.871 19.63 144.184 19.63 140.115C19.63 136.178 17.2893 133.492 14.1772 133.492C10.9056 133.492 9.7352 135.779 9.25642 138.333L8.53825 141.95C8.05947 144.53 7.02211 145.408 5.42618 145.408C3.13867 145.408 1.83532 143.413 1.83532 140.7C1.83532 137.881 2.87268 135.38 5.93155 135.088V132.88Z"
          fill="black"
        />
      </svg>
    </Wrap>
  )
}

const Wrap = styled.div`
  svg path {
    fill: ${props => props.theme.pageTheme.fg};
  }
`
