import React from 'react'
import { Flex, Box } from '@rebass/grid'
import { PageCaption } from '../global'
import { addImageSharpData } from '../../util/helpers'
import Img from 'gatsby-image'
import PageGalleryVideo from './page-gallery-video'

export default ({ data, allImages }) => {
  const dataGrid = addImageSharpData(data, allImages)
  const boxes = dataGrid.map((gridItem, index) => {
    let boxContent
    if (gridItem.type === 'image' && gridItem.childImageSharp) {
      boxContent = <Img fluid={gridItem.childImageSharp.fluid} alt="" />
    } else if (gridItem.type === 'video') {
      const foundVideos = allImages.edges.filter((asset, index) => {
        if (asset.node.relativePath === gridItem.src) {
          return true
        } else {
          return false
        }
      })
      if (foundVideos.length > 0) {
        // console.log(foundVideos[0], 'videooo')

        boxContent = (
          <PageGalleryVideo
            src={foundVideos[0].node.publicURL}
            ratio={gridItem.ratio}
          />
        )
      }
    }
    const caption = gridItem.caption ? (
      <PageCaption>{gridItem.caption}</PageCaption>
    ) : null

    return (
      <Box
        width={[1, gridItem.width / 10]}
        key={`box-${index}`}
        px={[2, 3]}
        my={[2, 3]}
      >
        {boxContent}
        {caption}
      </Box>
    )
  })

  return <Flex flexWrap={[`wrap`]}>{boxes}</Flex>
}
