const colors = {
  primary: '#454545',
  secondary: '#BBAFA9',
  tertiary: '#fff',
  tertiaryAlt: '#ebe7e5',
}

const font = {
  fontFamilyRegular: `'untitled-regular', 'sans-serif'`,
  fontFamilyMedium: `'untitled-medium', 'sans-serif'`,
  fontFamilyBold: `'untitled-bold', 'sans-serif'`,
  baseSizes: {
    sm: [`21px`, `27px`],
    md: [`23px`, `29px`],
    lg: [`23px`, `29px`],
  },
  smallSizes: {
    sm: [`16px`, `20px`],
    md: [`19px`, `24px`],
    lg: [`19px`, `24px`],
  },
  captionSizes: {
    sm: [`13px`, `18px`],
    md: [`15px`, `20px`],
    lg: [`15px`, `20px`],
  },
  largeSizes: {
    sm: [`36px`, `40px`],
    md: [`74px`, `78px`],
    lg: [`80px`, `84px`],
  },
  buttonSizes: {
    sm: [`26px`, `30px`],
    md: [`36px`, `40px`],
    lg: [`36px`, `40px`],
  },
}

const pageThemeOptions = {
  default: {
    bg: colors.tertiary,
    fg: colors.primary,
    fgAlt: colors.secondary,
    navBg: colors.secondary,
    navFg: colors.primary,
    navHover: colors.tertiary,
  },
  dark: {
    bg: `#454545`,
    fg: colors.tertiary,
    fgAlt: colors.secondary,
    navBg: colors.secondary,
    navFg: colors.primary,
    navHover: colors.tertiary,
  },
  brand: {
    bg: colors.secondary,
    fg: colors.primary,
    fgAlt: colors.tertiary,
    navBg: colors.secondary,
    navFg: colors.primary,
    navHover: colors.tertiary,
  },
  contactPage: {
    bg: colors.secondary,
    fg: colors.primary,
    fgAlt: colors.tertiary,
    navBg: colors.secondary,
    navFg: colors.primary,
    navHover: colors.tertiary,
  },
}

const pageTheme = pageThemeOptions.default

const space = [0, 4, 8, 16, 32, 64, 128, 256]

const breakpoints = ['900px', '1600px']

const sizing = {
  maxWidth: '1600px',
}

const fontSizing = (sizingObj) => `
  font-size: ${sizingObj.lg[0]};
	line-height: ${sizingObj.lg[1]};
	@media screen and (max-width: ${breakpoints[1]}) {
		font-size: ${sizingObj.md[0]};
    line-height: ${sizingObj.md[1]};
	}
	@media screen and (max-width: ${breakpoints[0]}) {
		font-size: ${sizingObj.sm[0]};
    line-height: ${sizingObj.sm[1]};
	}
`

const titleSizing = () => `
  font-size: 90px;
  letter-spacing: -0.005em;
  line-height: 110%;
  position: relative;
  left: -5px;
	@media screen and (max-width: 1370px) {
		font-size: 6.6vw;
    letter-spacing: -0.005em;
    left: -2px;
	}
	@media screen and (max-width: 900px) {
    font-size: 10.5vw;
    line-height: 118%;
    letter-spacing: -0.01em;
    left: -2px;
	}
`

const pageCopy = () => `
  color: ${colors.white};
  @media screen and (max-width: ${breakpoints[1]}) {
    
  }
  @media screen and (max-width: ${breakpoints[0]}) {
    
  }
`

const pageRowPad = (isPad) => `
  padding-left: ${isPad ? `calc(10% - 16px)` : `0px`};
  padding-right: ${isPad ? `calc(10% - 16px)` : `0px`};
  @media screen and (max-width: ${breakpoints[1]}) {
    padding-left: ${isPad ? `calc(8% - 16px)` : `0px`}; 
    padding-right: ${isPad ? `calc(8% - 16px)` : `0px`}; 
  }
  @media screen and (max-width: ${breakpoints[0]}) {
    padding-left: ${isPad ? `10px` : `0px`};
    padding-right: ${isPad ? `10px` : `0px`};
  }
`

const theme = {
  colors,
  font,
  space,
  breakpoints,
  sizing,
  fontSizing,
  titleSizing,
  pageCopy,
  pageRowPad,
  pageThemeOptions,
  pageTheme,
}
export const transDefault = 'transition:all 0.5s ease-in-out'

export default theme
