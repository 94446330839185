import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

export default props => {
  const { children, fullWidth, fullHeight, bg, ...rest } = props
  return (
    <Outer
      {...rest}
      isPad={fullWidth ? false : true}
      bg={bg ? bg : `transparent`}
      isFullHeight={fullHeight ? true : false}
    >
      <Inner isFullHeight={fullHeight ? true : false}>{children}</Inner>
    </Outer>
  )
}

const Outer = styled.div`
  ${props => props.theme.pageRowPad(props.isPad)};
  background: ${props => (props.bg ? props.bg : `transparent`)};
  ${space};
  height: ${props => (props.isFullHeight ? `100%` : `auto`)};
`

const Inner = styled.div`
  max-width: ${props => props.theme.sizing.maxWidth};
  margin: 0 auto;
  height: ${props => (props.isFullHeight ? `100%` : `auto`)};
`
