import React from 'react'
import { Flex, Box } from 'reflexbox/styled-components'
import styled from 'styled-components'
import { Link } from 'gatsby'
import LogomarkAlt from '../../svg/logomark-alt'

export default () => {
  return (
    <Flex>
      <Box width={[0, `${(2 / 10) * 100}%`]} pb={[3, 0]} px={[0, 3]}>
        <LogoWrap>
          <Link to="/">
            <LogomarkAlt />
          </Link>
        </LogoWrap>
      </Box>
      <Box width={[1, `${(8 / 10) * 100}%`]} px={[2, 3]} pb={[2, 6, 7]}>
        <PageTitle>
          We start with the
          <br />
          details that matter <br /> to you.{' '}
          <Link to="/contact">Let's talk</Link>
        </PageTitle>
      </Box>
    </Flex>
  )
}

const PageTitle = styled.h1`
  margin: 0px;
  ${props => props.theme.titleSizing()};
  font-weight: normal;
  color: ${props => props.theme.pageTheme.navFg};
  a {
    text-decoration: none;
    color: ${props => props.theme.pageTheme.navFg};
    border-bottom: 3px solid ${props => props.theme.pageTheme.navFg};
    &:hover {
      color: ${props => props.theme.pageTheme.navHover};
      border-bottom: 3px solid ${props => props.theme.pageTheme.navHover};
    }
  }
  @media (max-width: 992px) {
  }
`

const LogoWrap = styled.div`
  position: relative;
  top: 12px;
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    display: none;
  }
`
