import React, { useState } from 'react'
import { Link } from 'gatsby'
import { PageRow } from './global'
import styled, { css } from 'styled-components'
import { Flex, Box } from 'reflexbox/styled-components'
import ScrollLock from 'react-scrolllock'
import IconInstagram from '../svg/icon-instragram'
import Logomark from '../svg/logomark'
import Nav from './nav'
import Hamburger from './nav/hamburger'
import theme from '../styles/theme'

export default ({ siteTitle, location }) => {
  const [isToggled, setIsToggled] = useState(false)

  let fg = theme.colors.primary
  if (location) {
    const slug = location.pathname.replace(/\/$/, '')
    if (slug === '/projects') {
      fg = theme.colors.tertiary
    }
  }

  const onToggle = () => {
    setIsToggled(!isToggled)
  }

  return (
    <Wrap>
      <ScrollLock isActive={isToggled} />
      <PageRow mb={[5, 5, 5]} pt={[`1.8rem`, `3rem`]}>
        <Flex justifyContent="space-between" alignItems="center">
          <Box px={[2, 3]}>
            <Logo>
              <Link to="/">
                <Logomark />
              </Link>
            </Logo>
          </Box>
          <Box px={[2, 3]}>
            <Links>
              <Social>
                <a
                  href="https://www.instagram.com/jhdixonbuilders"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconInstagram fg={fg} mcss={StyledIconInstagram} />
                </a>
              </Social>
              <Hamburger onToggle={onToggle} isToggled={isToggled} fg={fg} />
            </Links>
          </Box>
        </Flex>
      </PageRow>
      <Nav onToggle={onToggle} isToggled={isToggled} location={location} />
    </Wrap>
  )
}

const StyledIconInstagram = css`
  display: none;
  @media screen and (min-width: 320px) {
    display: block;
  }
`

const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const Social = styled.div`
  a,
  svg {
    display: block;
  }
`

const Logo = styled.div`
  a,
  svg {
    display: block;
  }
`

const Links = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
`
