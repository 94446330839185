import React from 'react'
// import { Link } from 'gatsby'
import styled from 'styled-components'
import useWindowScroll from '@react-hook/window-scroll'
import useWindowSize from '@rehooks/window-size'
import LogomarkVert1 from '../../svg/logomark-vert-1'
import LogomarkVert2 from '../../svg/logomark-vert-2'

export default ({ pageHeight }) => {
  const scrollY = useWindowScroll(60)
  const windowSize = useWindowSize()

  // console.log('pageHeight', parseInt(pageHeight))
  // console.log('windowSize', windowSize)

  if (!windowSize) return null

  let blockHeight = 0
  let isActive = false

  if (windowSize.innerWidth >= 900) {
    let height = windowSize.innerHeight
    blockHeight = height

    // console.log('scroll', scrollY, height, height * 0.6, height * 0.9)

    if (scrollY > height * 0.2 && scrollY < pageHeight - height * 2) {
      isActive = true
    } else {
      isActive = false
    }
    return (
      <Wrap isActive={isActive}>
        <Inner>
          <LogoVert1 height={`${blockHeight}px`}>
            <LogomarkVert1 />
          </LogoVert1>
          <LogoVert2 height={`${blockHeight}px`}>
            <LogomarkVert2 />
          </LogoVert2>
        </Inner>
      </Wrap>
    )
  } else {
    return null
  }
}

const Wrap = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 1px;
  z-index: 2;
  transition: opacity 0.4s ease-out;
  opacity: ${props => (props.isActive ? `1` : `0`)};
  pointer-events: none;
`

const Inner = styled.div`
  /* max-width: 1866px; 
  width: 94%;*/
  width: 100%;
  position: relative;
  margin: 0 auto;
  @media screen and (max-width: 1600px) {
    width: 100%;
  }
`

const LogoVert1 = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  height: ${props => (props.height ? props.height : `auto`)};
  width: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(255, 0, 0, 0.5); */
`

const LogoVert2 = styled(LogoVert1)`
  right: 0px;
  left: auto;
`
