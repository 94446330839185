import React from 'react'
import styled from 'styled-components'

export default ({ children }) => {
  return (
    <Caption>
      <p dangerouslySetInnerHTML={{ __html: children }} />
    </Caption>
  )
}

const Caption = styled.div`
  ${props => props.theme.fontSizing(props.theme.font.captionSizes)}
  p {
    margin: 0;
    padding: 1em 0 0 0;
  }
  a {
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
  }
`
