import React from 'react'
import styled from 'styled-components'

export default ({ onToggle, isToggled, fg }) => {
  return (
    <Hamburger onClick={onToggle} isToggled={isToggled} fg={fg}>
      <Line1 isToggled={isToggled} />
      <Line2 isToggled={isToggled} />
      <Line3 isToggled={isToggled} />
    </Hamburger>
  )
}

const Line1 = styled.span`
  left: 0;
  top: 0;
  transform: ${props =>
    props.isToggled ? `translateY(11px) translateX(0) rotate(45deg)` : `none`};
`

const Line2 = styled.span`
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: ${props => (props.isToggled ? `0` : `1`)};
`

const Line3 = styled.span`
  left: 0;
  bottom: 0;
  transform: ${props =>
    props.isToggled
      ? `translateY(-10px) translateX(0) rotate(-45deg)`
      : `none`};
`

const Hamburger = styled.button`
  outline: none;
  border: none;
  width: 40px;
  height: 24px;
  margin-left: 30px;
  position: relative;
  padding: 0;
  cursor: pointer;
  span {
    position: absolute;
    width: 100%;
    height: 3px;
    display: block;
    background: ${props => (props.fg ? props.fg : props.theme.pageTheme.navFg)};
    transition: all 0.2s ease-out;
  }
  &:hover {
    ${Line1} {
      top: ${props => (props.isToggled ? `0px` : `2px`)};
    }
    ${Line3} {
      bottom: ${props => (props.isToggled ? `0px` : `2px`)};
    }
  }
`
