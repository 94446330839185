import React from 'react'
import styled from 'styled-components'
import Player from 'react-background-video-player'

export default ({ src, ratio }) => {
  return (
    <Wrap ratio={ratio}>
      <Inner>
        <Player src={src} containerWidth={100} containerHeight={100} />
      </Inner>
    </Wrap>
  )
}

const Wrap = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: ${props => `${props.ratio * 100}%`};
  position: relative;
`

const Inner = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
`
