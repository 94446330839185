import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* Added to Fix Footer to bottom of viewport */

  body {
    scroll-behavior: smooth;
    margin: 0;
  }

  html, body {
    height: 100%;
  }

  footer {
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
    }
  }

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    transition: background .1s ease-out;
    background: ${props => props.theme.pageTheme.bg};
    color: ${props => props.theme.pageTheme.fg};
    line-height: 1;
    ${props => props.theme.fontSizing(props.theme.font.baseSizes)};
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-weight: 400;
    font-family: ${props => props.theme.font.fontFamilyRegular};
    @media screen and (max-width: ${props => props.theme.breakpoints[1]}) {
      
    }
    @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
      
    }
  }

  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  button,
  input {
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
    resize: none;
    &:focus {
      outline: none;
    }
    &:invalid {
      box-shadow: none;
    }
  }

  strong {
    font-family: ${props => props.theme.font.fontFamilyBold};
    font-weight: normal;
  }

  /* https://stackoverflow.com/questions/1542320/margin-while-printing-html-page */
  @page { 
    /* size: auto; */ /* auto is the initial value */ 
    /* this affects the margin in the printer settings */ 
    /* margin: 18mm 12mm 5mm 12mm; */
  } 

  @media print {
    body { 
      /* this affects the margin on the content before sending to printer */ 
      margin: 20px 0px;  
    } 
  }

  @media print {
    html, body {
      height: auto;  
      background: #ffffff;
      height: 99%;
    }
  }
`
export default GlobalStyle
