import React from 'react'
import styled from 'styled-components'

export default () => {
  return (
    <Wrap>
      <svg
        width="161"
        height="22"
        viewBox="0 0 161 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.852 13.9308C10.852 17.2525 9.54081 19.4018 6.52792 19.4018C4.21246 19.4018 2.45494 18.0341 2.31546 15.2149H0C0.111588 19.1506 2.9292 21.3 6.52792 21.3C10.7404 21.3 13.2511 18.397 13.2511 13.9029V1.03491H10.852V13.9308Z"
          fill="black"
        />
        <path
          d="M19.9939 20.965H23.0346V17.9224H19.9939V20.965Z"
          fill="black"
        />
        <path
          d="M30.9911 20.965H33.3903V11.8373H45V20.965H47.4504V1.03491H45V9.82759H33.3903V1.03491H30.9911V20.965Z"
          fill="black"
        />
        <path
          d="M55.4271 20.965H58.4679V17.9224H55.4271V20.965Z"
          fill="black"
        />
        <path
          d="M73.4824 1.03491H66.4244V20.965H73.4824C79.2571 20.965 83.1905 17.0013 83.1905 11C83.1905 5.02651 79.2571 1.03491 73.4824 1.03491ZM73.4824 3.04466C78.1133 3.04466 80.7077 6.19887 80.7077 11C80.7077 15.8289 78.1133 18.9552 73.4824 18.9552H68.7956V3.04466H73.4824Z"
          fill="black"
        />
        <path d="M89.794 20.965H92.1932V1.03491H89.794V20.965Z" fill="black" />
        <path
          d="M113.758 20.965H116.547L108.485 10.33L115.515 1.03491H112.921L107.202 8.68315L101.483 1.03491H98.6654L105.807 10.4975L97.9122 20.965H100.507L107.09 12.1723L113.758 20.965Z"
          fill="black"
        />
        <path
          d="M138.32 11C138.32 5.02651 134.777 0.699951 129.253 0.699951C123.729 0.699951 120.186 5.02651 120.186 11C120.186 17.0013 123.729 21.3 129.253 21.3C134.777 21.3 138.32 17.0013 138.32 11ZM135.837 11C135.837 15.8289 133.605 19.2623 129.253 19.2623C124.873 19.2623 122.641 15.8289 122.641 11C122.641 6.19887 124.873 2.73761 129.253 2.73761C133.605 2.73761 135.837 6.19887 135.837 11Z"
          fill="black"
        />
        <path
          d="M147.957 1.03491H145V20.965H147.343V3.9658H147.399L158.725 20.965H160.957V1.03491H158.642V16.9455H158.586L147.957 1.03491Z"
          fill="black"
        />
      </svg>
    </Wrap>
  )
}

const Wrap = styled.div`
  svg path {
    fill: ${props => props.theme.pageTheme.fg};
  }
`
