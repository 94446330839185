import React from 'react'

export default () => {
  return (
    <svg
      id="arrow-right"
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2021 41.5688L40.6367 21.1099L20.2021 0.675293L17.4189 3.43408L33.0928 19.1079L0.255859 19.1079V23.0142L33.2148 23.0142L17.4189 38.7856L20.2021 41.5688Z"
        fill="white"
      />
    </svg>
  )
}
