import React from 'react'
import styled from 'styled-components'
import ArrowRight from '../../svg/arrow-right'

export default () => {
  return (
    <form
      name="Contact Form"
      method="POST"
      data-netlify="true"
      action="/contact-success"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="Contact Form" />
      <input type="hidden" name="bot-field" />

      <FormRow>
        <FormLabel htmlFor="name">Name</FormLabel>
        <FormField>
          <input
            id="name"
            type="text"
            name="name"
            placeholder="Enter name"
            required
          />
        </FormField>
      </FormRow>

      <FormRow>
        <FormLabel htmlFor="email">Email</FormLabel>
        <FormField>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Enter email"
            required
          />
        </FormField>
      </FormRow>

      <FormRow>
        <FormLabelNoneLine htmlFor="message">Message</FormLabelNoneLine>

        <FormField>
          <textarea
            id="message"
            name="message"
            required
            defaultValue="Tell us more"
          />
        </FormField>
      </FormRow>

      <FormRow>
        <Formbutton type="submit">
          Send
          <ArrowRight />
        </Formbutton>
      </FormRow>
    </form>
  )
}

// const Form = styled.div`
//   display: block;
// `

const FormRow = styled.div`
  display: block;
  ${props => props.theme.titleSizing()};
`

const FormLabel = styled.label`
  display: block;
  padding: 15px 0px 15px 0px;
  border-bottom: 2px solid ${props => props.theme.colors.tertiary};
`

const FormLabelNoneLine = styled.label`
  display: block;
  padding-bottom: 10px;
  padding-top: 10px;
`

const FormField = styled.div`
  textarea {
    margin-top: 10px;
    width: 100%;
    height: 10em;
    padding: 10px;
    border: none;
    outline: none;
    font-family: inherit;
    ${props => props.theme.fontSizing(props.theme.font.smallSizes)};
  }

  input {
    color: ${props => props.theme.colors.tertiary};
    border-bottom: 2px solid ${props => props.theme.colors.tertiary};
    padding-bottom: 6px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    ::placeholder {
      color: ${props => props.theme.colors.tertiaryAlt};
    }
  }
`
const Formbutton = styled.button`
  cursor: pointer;
  display: block;
  padding: 10px 90px 10px 20px;
  margin-bottom: 80px;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.tertiary};
  position: relative;
  &:hover {
    svg {
      transform: translateX(8px);
    }
  }

  svg {
    position: absolute;
    right: 30px;
    top: 45px;
    transition: all 0.35s;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding-right: 80px;
    svg {
      width: 30px;
      top: 19px;
    }
  }
`
