import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex } from 'reflexbox/styled-components'
import { PageRow } from '../global'
import LogomarkAlt from '../../svg/logomark-alt'
import theme from '../../styles/theme'
import ContactForm from '../contact/form'
import Foot from './foot'

const Contact = () => {
  return (
    <PageRow bg={theme.colors.secondary}>
      <Flex>
        <Box
          width={[0, `${(2 / 10) * 100}%`]}
          // pt={[3, 5]}
          // pb={[3, 0]}
          // px={[0, 3]}
        >
          <LogoWrap>
            <Link to="/">
              <LogomarkAlt />
            </Link>
          </LogoWrap>
        </Box>
        <Box ml={[0]} width={[1, `51%`]} px={[2, 3]} pt={[3, 5]} pb={[0, 0]}>
          {/* <ContactForm /> */}
        </Box>
      </Flex>
      <Foot />
    </PageRow>
  )
}

const LogoWrap = styled.div`
  position: relative;
  top: 28px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints[0]}) {
    display: none;
  }
`

export default Contact
