import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default () => {
  return (
    <Copyright>
      <p>
        <span>©{new Date().getFullYear()} J.H.Dixon Builders Pty Ltd</span>
        <MobBreak />
        <span>ACN 40156430385</span>
        <span>QBCC 1243070</span>
        <MobBreak />
        <Link to="/privacy">Privacy</Link>
      </p>
    </Copyright>
  )
}

const Copyright = styled.div`
  color: ${props => props.theme.pageTheme.navFg};
  ${props => props.theme.fontSizing(props.theme.font.captionSizes)};
  padding-bottom: 4vh;
  p {
    margin: 0;
  }
  a,
  span {
    margin-right: 1em;
  }
  span {
    display: inline-block;
    a {
      margin-right: 0px;
      text-decoration: none;
    }
  }
  a {
    display: inline-block;

    text-decoration: underline;
    color: ${props => props.theme.pageTheme.navFg};
    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.pageTheme.navHover};
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    a,
    span {
      margin-right: 0.8em;
    }
  }
`

const MobBreak = styled.br`
  display: none;
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    display: block;
  }
`
