import React from 'react'
import { Flex, Box } from 'reflexbox/styled-components'
import { PageTitle } from '../global'
import styled from 'styled-components'

export default ({ children, hideBorder }) => {
  const border = hideBorder ? null : (
    <Box width={[1]} px={[2, 3]}>
      <Border />
    </Box>
  )

  return (
    <Flex flexDirection="column">
      <Box ml={[0]} width={[1]} px={[0]}>
        <Box
          ml={[0, `${(2 / 10) * 100}%`]}
          width={[1, 8 / 10]}
          px={[2, 3]}
          hideBorder={hideBorder}
        >
          <PageTitle>{children}</PageTitle>
        </Box>
      </Box>
      {border}
    </Flex>
  )
}

const Border = styled.div`
  height: 1px;
  border-bottom: ${props =>
    props.hideBorder ? `none` : `3px solid ${props.theme.pageTheme.fgAlt}`};
`
