import React from 'react'
import styled from 'styled-components'

export default ({ children, noMargin = false }) => {
  return <PageCopy noMargin={noMargin}>{children}</PageCopy>
}

const PageCopy = styled.div`
  color: ${props => props.theme.pageTheme.fg};
  margin-bottom: ${props => (props.noMargin ? `0em` : `2em`)};
  p {
    margin-top: 0px;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  h3 {
    margin-top: 0px;
    margin-bottom: 1em;
    font-family: ${props => props.theme.font.fontFamilyMedium};
    ${props => props.theme.fontSizing(props.theme.font.baseSizes)};
    font-weight: normal;
  }
  span {
    color: ${props => props.theme.pageTheme.fgAlt};
  }
  a {
    color: ${props => props.theme.pageTheme.fg};
  }
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    margin-bottom: ${props => (props.noMargin ? `0em` : `2em`)};
  }
`
