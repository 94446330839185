import React from 'react'
import Collapsible from 'react-collapsible'
import styled from 'styled-components'
import Arrrow from '../../svg/arrrow'
import { Flex, Box } from 'reflexbox/styled-components'

export default ({ title, content, isOpen }) => {
  const trigger = (
    <Flex px={[2, 3]}>
      <Box width="100%">
        <Trigger>
          <Title>{title}</Title>
          <ArrowWrap>
            <Arrrow />
          </ArrowWrap>
        </Trigger>
      </Box>
    </Flex>
  )

  return (
    <Wrap>
      <Collapsible trigger={trigger} open={isOpen}>
        <Inner>{content}</Inner>
      </Collapsible>
    </Wrap>
  )
}

const ArrowWrap = styled.div`
  position: absolute;
  right: 0;
  top: 44px;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
  transform: rotate(0deg);

  @media screen and (max-width: 1250px) {
    top: 33%;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    top: 25%;
    width: 38px;
  }
  @media screen and (max-width: 500px) {
    top: 22%;
    width: 32px;
  }
`

const Trigger = styled.span`
  display: block;

  color: ${props => props.theme.colors.secondary};
  border-top: 3px solid ${props => props.theme.colors.secondary};
  padding: 10px 0 20px 0;
  cursor: pointer;
  position: relative;
  padding-left: ${props => `calc(20% + ${props.theme.space[1]}px)`};
  &:hover {
    border-top: 3px solid ${props => props.theme.colors.primary};
  }
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding: 10px 0;
    padding-left: 0%;
  }
`

const Title = styled.span`
  ${props => props.theme.titleSizing()}
`

const Inner = styled.div`
  padding-bottom: 50px;
`

const Wrap = styled.div`
  .is-open {
    ${ArrowWrap} {
      transform: rotate(180deg);
    }
  }
`
