import React from 'react'
import { Flex, Box } from 'reflexbox/styled-components'
import FootCopy from './foot-copy'

export default () => {
  return (
    <Flex>
      <Box ml={[0, `${(2 / 10) * 100}%`]} width={[1, 6 / 8]} px={[2, 3]}>
        <FootCopy />
      </Box>
    </Flex>
  )
}
