import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import Header from './header'
import Nav from './nav'
import NavContact from './nav/contact'
import ScrollLogos from './global/scroll-logos'
import _ from 'lodash'
import { Helmet } from 'react-helmet'

import '../css/fonts.css'

const isBrowser = () => typeof window !== 'undefined'

export default ({ children, bg, hideContact, location }) => {
  const [currTheme, setCurrTheme] = useState(theme)
  const [pageHeight, setPageHeight] = useState(0)

  let slug = ''
  if (location) {
    slug = location.pathname.replace(/\/$/, '')
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    if (slug === '/projects') {
      // short-term fix for good form
      onThemeChange('dark')
      // document.body.style.backgroundColor = '#454545'
    } else if (slug === '/contact') {
      onThemeChange('contactPage')
      // short-term fix for good form
      // document.body.style.backgroundColor = '#BBAFA9'
    } else {
      onThemeChange()
      // short-term fix for good form
      // document.body.style.backgroundColor = '#fff'
    }
  }, [slug])

  const onThemeChange = (themeKey) => {
    const newTheme = _.cloneDeep(theme)
    if (themeKey && theme.pageThemeOptions[themeKey]) {
      newTheme.pageTheme = theme.pageThemeOptions[themeKey]
    } else {
      newTheme.pageTheme = theme.pageThemeOptions.default
    }
    setCurrTheme(newTheme)
  }

  const refCallback = (element) => {
    if (element) {
      setPageHeight(element.getBoundingClientRect().height)
    }
  }

  const childrenWithProps = React.Children.map(children, (child, i) =>
    React.cloneElement(child, {
      onThemeChange: onThemeChange,
    })
  )

  const footNav =
    slug === '/contact' ? (
      <NavContact />
    ) : (
      <Nav isFoot={true} location={location} />
    )

  useEffect(() => {
    const script = document.createElement('script')
    // script.src = 'http://localhost:3020/client/jhdixon.js'
    script.src = 'https://goodform-app.vercel.app/client/jhdixon.js'
    document.body.appendChild(script)
  }, [])

  return (
    <>
      <ThemeProvider theme={currTheme}>
        <Wrap ref={refCallback}>
          <Header
            siteTitle={data.site.siteMetadata.title}
            location={location}
          />
          <Main removePadding={slug === '/contact'}>{childrenWithProps}</Main>
          {footNav}
          {isBrowser() ? <ScrollLogos pageHeight={pageHeight} /> : null}
        </Wrap>
      </ThemeProvider>
      <GlobalStyle theme={currTheme} />
    </>
  )
}

const Main = styled.main`
  padding-top: 200px;
  padding-bottom: ${(props) => (props.removePadding ? `100px` : `200px`)};
  @media screen and (max-width: ${(props) => props.theme.breakpoints[0]}) {
    padding-top: 8rem;
    padding-bottom: ${(props) => (props.removePadding ? `4rem` : `8rem`)};
  }
`

const Wrap = styled.div``
