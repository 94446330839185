import React from 'react'
import { Box } from 'reflexbox/styled-components'
import styled from 'styled-components'
import { Link } from 'gatsby'
import IconInstagram from '../../svg/icon-instragram'
import Hamburger from './hamburger'

export default ({ isFoot, onToggle, isToggled }) => {
  return (
    <div>
      <Head
        ml={[0, `${(2 / 10) * 100}%`]}
        width={[1, `${(8 / 10) * 100}%`]}
        px={[2, 3]}
      >
        <Outer>
          <Links>
            <li>
              <Link to="/projects" onClick={onToggle}>
                Projects
              </Link>
            </li>
            <li>
              <Link to="/approach" onClick={onToggle}>
                Approach
              </Link>
            </li>
            <li>
              <Link to="/company" onClick={onToggle}>
                Company
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={onToggle}>
                Contact
              </Link>
            </li>
          </Links>
          <Trigger>
            <Social>
              <a
                href="https://www.instagram.com/jhdixonbuilders"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconInstagram />
              </a>
            </Social>
            {!isFoot ? (
              <Hamburger onToggle={onToggle} isToggled={isToggled} />
            ) : null}{' '}
          </Trigger>
        </Outer>
      </Head>
      <Box width={[1]} px={[2, 3]}>
        <Border />
      </Box>
    </div>
  )
}

const Trigger = styled.div`
  position: absolute;
  right: 0;
  top: 3px;
  display: flex;
  justify-content: flex-end;
`

const Social = styled.div`
  a,
  svg {
    display: block;
  }
`

const Head = styled(Box)`
  position: relative;
`

const Border = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 3px solid ${props => props.theme.pageTheme.navFg};
`

const Outer = styled.div`
  align-items: flex-start;
  padding-bottom: 3.5rem;
  position: relative;
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding-bottom: 1.5rem;
  }
`

const Links = styled.ul`
  padding: 0px;
  margin: 0px;
  justify-content: space-between;
  display: flex;
  width: 80%;
  line-height: 100%;
  li {
    list-style-type: none;
  }
  a {
    border-style: none;
    text-decoration: none;
    display: block;
    padding: 0px;
    color: ${props => props.theme.pageTheme.navFg};
    ${props => props.theme.transDefault};
    &:hover {
      color: ${props => props.theme.pageTheme.navHover};
      text-decoration: none;
      padding: 0px;
    }
  }

  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    width: 75%;
    flex-direction: column;
    font-size: 130%;
    li {
      margin-bottom: 0.5em;
    }
  }
`
